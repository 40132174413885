<svelte:options tag="prodamsp-componente-consentimento" />

<script>
    import { onMount } from "svelte";
    /* import url from "./url";*/

    /*controles de estado*/
    let CookieConfig = {};
    let AcessoAnonimo = false;
    let ExibePanelTermo = true;
    let ExibePanelUsoCookies = true;
    let ExisteNaAPI = false;
    let algumaPermissaoObrigatoriaNaoEfetuada = false;
    let listPermissaoObrigatoriaNaoEfetuada = [];
    let AceiteExpirou = true;
    let isOpen = false;
    let isOpenDefincoes = false;
    let isOpenPolitica = false;
    let periodoRevogacao = 365;
    let firefoxAgent =
        navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

    /*Export  - parâmetros gerais do componente  */
    export let pessoacompgrafico = "";
    export let tppessoacompgrafico = "";
    export let indsaidasimplificada = "0";
    export let tokencac;
    export let timeouttokencac = 180000; //segundos
    export let urlapi = "http://webdesenv5.prodam/SF9421_api/api/v1/cookies/";
    export let urllogout = "";
    export let exibepanelfooter = "0";
    export let exibepanelatencao = "";
    export let showpanelatencao = "0";
    export let str_tipos_cookies = "";
    export let str_tipos_cookies_necessarios = "";

    /*Export  - regiao do footer*/
    export let name;
    export let footer_textoatencao = "";
    export let footer_textowebsite = "";
    export let footer_website;
    export let panel_textodefinicaocookies = "";
    export let footer_urlpolitica;
    export let panel_textolinkpolitica = "";

    /*Export  - regiao do panel central*/
    export let panel_central_title;
    export let panel_textoatencao;
    export let panel_textoconsentimento;

    /*Export  - regiao do modal*/
    export let modal_central_title;
    export let modal_termo_responsabilidade_title;
    export let modal_termo_textoresponsabilidade;
    export let modal_termo_preencherresponsabilidade;
    export let modal_usocookie_title;
    export let modal_usocookie_texto;
    export let modal_usocookie_preencher;
    export let modal_usocookie_txsimplificado;
    export let tx_confirmar_sair =
        "Não foram selecionadas as opções minimamente necessárias para o funcionamento adequado da aplicação. Clique em cancelar para selecionar os termos, ou OK para sair e ser desconectado";

    /* Model CheckBox */
    let isCheckedAceiteTermos = false;
    let tiposDeCookiesObrigatorios = [];
    let tiposDeCookies = [];
    let tiposDeCookiesPosEscolha = []; //para gravação
    let situacaoConsentimento = "";

    export function getCookieConfig() {
        console.log(CookieConfig);
    }

    export function GetSituacaoConsentimento() {
        return situacaoConsentimento;
    }

    /*inicialização do componente ---------------------------------------------- ---------------------*/
    onMount(() => {
        return Iniciar();
    });

    export function Iniciar() {
        //console.clear();
        /* console.log("Iniciando o componente...")*/
        showpanelatencao = exibepanelatencao == "1" ? "1" : "0";
        HideModalConfirmAceiteTermoResponsa();

        /*carrega os tipos de cookies*/
        tiposDeCookies = JSON.parse(str_tipos_cookies);
        tiposDeCookiesObrigatorios = JSON.parse(str_tipos_cookies_necessarios);
        tiposDeCookiesPosEscolha = tiposDeCookies; /* temporaria */

        /* verifica se os parametros foram minimanente oferecidos*/
        ValidarConfiguracoesCookies(
            pessoacompgrafico,
            tppessoacompgrafico,
            indsaidasimplificada,
            tokencac
        );
        /* set AcessoAnonimo=true para anonimo   vazio*/
        if (pessoacompgrafico === "") {
            AcessoAnonimo = true;
        } else if (pessoacompgrafico !== "") {
            AcessoAnonimo = false;
        }

        GetParametroRevogacao()
            .then(
                function (result) {
                    periodoRevogacao = result;
                },
                function (err) {
                    periodoRevogacao = 365;
                }
            )
            .finally(function () {
                /* carrega configuracoes já existente na api, se não for anonimo */
                if (!AcessoAnonimo) {
                    CarregaConfiguracoesDoUsuarioAPI()
                        .then(
                            function (newResult) {
                                console.log(
                                    "[A]fim carregar dados da api: ",
                                    newResult
                                );
                            },
                            function (falha) {
                                console.log(
                                    "[A]fim carregar dados da api: ",
                                    falha
                                );
                            }
                        )
                        .finally(() => ValidaExibicaoPainel());
                } else {
                    ValidaExibicaoPainel();
                    console.log("[NA]fim carregar dados da api");
                }
            });
    }

    /*click do botao Continue (5)*/
    export function SalvarConsentimento() {
        let retorno = { CodRetorno: 0, MensagemRetorno: "" };

        try {
            retorno = salvar();

            if (retorno.CodRetorno == 888) {
                //operação cancelada
                situacaoConsentimento = "888";
            } else if (retorno.CodRetorno == 889) {
                //888=usuário confirmou faltando os obrigatorios
                HideModalConfirmAceiteTermoResponsa();

                situacaoConsentimento = "889";

                //e só redireciona se tiver urllogout

                setTimeout(function () {
                    if (urllogout != "") {
                        Redirecionar(urllogout);
                    }
                    showpanelatencao = "0";
                }, 800);
            } else {
                /* oculta os modais internos e  marca que efetuou o consentimento*/
                HideModalConfirmAceiteTermoResponsa();
                situacaoConsentimento = "1";
                showpanelatencao = "0";
            }
        } catch (error) {
            retorno = { CodRetorno: 999, MensagemRetorno: error };
            console.log(
                "Erro ao salvar no componente de configuracao de cookies.!",
                error
            );
            HideModalConfirmAceiteTermoResponsa();
            showpanelatencao = "0";
        }

        //chama evento callback
        this.dispatchEvent(
            new CustomEvent("SalvarConsentimento", {
                composed: true,
                detail: { key: retorno },
            })
        );
    }

    /* botão 1*/
    export function AutorizarCookiesEPolitica() {
        isCheckedAceiteTermos = true;
        ConfigurarListaParaGravacao(false);
        let retorno = { CodRetorno: 0, MensagemRetorno: "" };
        try {
            retorno = salvar();
            situacaoConsentimento = "1";
        } catch (e) {
            retorno = e;
            console.log(
                "Erro ao carregar o componente de configuracao de cookies.!",
                e.message
            );
        }

        //chama evento callback
        this.dispatchEvent(
            new CustomEvent("AutorizarCookiesEPolitica", {
                composed: true,
                detail: { key: retorno },
            })
        );

        /* oculta os modais internos */
        HideModalConfirmAceiteTermoResponsa();
        showpanelatencao = "0";
    }

    /*click do botao 2*/
    export function AutorizarCookiesNecessariosEPolitica() {
        /*guarda o estado do indicardor para voltar ao estado de postback*/
        let _indsaidasimplificada = indsaidasimplificada;
        indsaidasimplificada = "1";
        isCheckedAceiteTermos = true;

        ConfigurarListaParaGravacao(true);

        let retorno = { CodRetorno: 0, MensagemRetorno: "" };

        try {
            retorno = salvar();
            situacaoConsentimento = "1";
        } catch (e) {
            retorno = e;
            console.log(
                "Erro ao carregar o componente de configuracao de cookies.!",
                e.message
            );
        }

        /*retorar ao estado de configuração,  ao estado de postback*/
        indsaidasimplificada = _indsaidasimplificada;

        /*chama evento callback*/
        this.dispatchEvent(
            new CustomEvent("AutorizarCookiesNecessariosEPolitica", {
                composed: true,
                detail: { key: retorno },
            })
        );

        /* oculta os modais internos */
        HideModalConfirmAceiteTermoResponsa();
        showpanelatencao = "0";
    }

    /*click do botao 3*/
    export function AutorizarPoliticaEEscolherCookies() {
        // isCheckedAceiteTermos = false;

        /*exibe o painel para escolher os cookies */
        isOpen = !isOpen;
    }

    /*click do botao 4*/
    export function SairSemAutorizar(e) {
        //se houver popup aberto
        HideModalConfirmAceiteTermoResponsa();
        let result = { CodRetorno: "00", MensagemRetorno: "" };
        var r = confirm(tx_confirmar_sair);

        if (r == true) {
            situacaoConsentimento = "889";
            isCheckedAceiteTermos = false;
            DesistirAceiteTermoResponsaSimplAnonimo();

            if (!AcessoAnonimo) {
                //retornoAssincrono = {CodRetorno:"11", MensagemRetorno:"Obtenha o Retorno getRetornoAssincrono(); "};
                FinalizarConfiguracaoModoNaoAnonimo().then(function (retorno) {
                    result = retorno;

                    if (urllogout != "") {
                        setTimeout(function () {
                            if (urllogout != "") {
                                Redirecionar(urllogout);
                            }
                        }, 800);
                    }
                });
            }

            showpanelatencao = "0";

            if (urllogout != "") {
                setTimeout(function () {
                    if (urllogout != "") {
                        Redirecionar(urllogout);
                    }
                }, 800);
            }
        }

        /* comportamento estranho do firefox ...*/
        if (!firefoxAgent) {
            //chama evento callback
            this.dispatchEvent(
                new CustomEvent("SairSemAutorizar", {
                    composed: true,
                    detail: { key: result },
                })
            );
        } else {
            setTimeout(function () {
                //chama evento callback
                this.dispatchEvent(
                    new CustomEvent("SairSemAutorizar", {
                        composed: true,
                        detail: { key: result },
                    })
                );
            }, 1200);
        }
    }

    function ValidaExibicaoPainel() {
        /* carrega configuracoes dos cookies no checkbox */
        CarregaConfiguracoesDoUsuarioCookie();
        console.log(
            "algumaPermissaoObrigatoriaNaoEfetuada",
            algumaPermissaoObrigatoriaNaoEfetuada
        );
        console.log(
            "listPermissaoObrigatoriaNaoEfetuada",
            listPermissaoObrigatoriaNaoEfetuada
        );
        console.log("ExisteNaAPI", ExisteNaAPI);
        console.log("AceiteExpirou", AceiteExpirou);
        console.log("AcessoAnonimo", AcessoAnonimo);

        if (
            algumaPermissaoObrigatoriaNaoEfetuada ||
            (ExisteNaAPI && AceiteExpirou) ||
            (!ExisteNaAPI && !AcessoAnonimo)
        ) {
            /*registra um refresh para obter novo token*/
            let timeout = timeouttokencac;
            setTimeout(function () {
                if (showpanelatencao == "1") {
                    //alert('O componente de consentimento de cookies expirou e será recarregado.');
                    location.reload();
                }
            }, timeout);

            //só mostra o painel se != "0" ou AceiteExpirou no modo nao anonimo
            if (
                exibepanelatencao != "0" ||
                (AceiteExpirou && !AcessoAnonimo) ||
                (algumaPermissaoObrigatoriaNaoEfetuada &&
                    exibepanelatencao != "0")
            ) {
                showpanelatencao = "1";
            }
        } else {
            //só mostra o painel se != "0" ou AceiteExpirou  no modo nao anonimo

            if (
                exibepanelatencao != "0" ||
                (AceiteExpirou && !AcessoAnonimo) ||
                (algumaPermissaoObrigatoriaNaoEfetuada &&
                    exibepanelatencao != "0")
            ) {
                showpanelatencao = "1";
            } else {
                showpanelatencao = "0";
            }
        }
    }

    /* funcoes estado do componente ------------------------------------------------------------------------------*/

    function CarregaConfiguracoesDoUsuarioCookie() {
        algumaPermissaoObrigatoriaNaoEfetuada = false;

        /*Obter dados do cookie*/
        if (checkIfCookieExist("SWCookieConfig") || ExisteNaAPI) {
            var data =
                getCookie("SWCookieConfig") != ""
                    ? getCookie("SWCookieConfig")
                    : JSON.stringify(CookieConfig);

            CookieConfig = JSON.parse(data);

            if (!Object.entries) {
                Object.entries = function (obj) {
                    var ownProps = Object.keys(obj),
                        i = ownProps.length,
                        resArray = new Array(i); // preallocate the Array
                    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

                    return resArray;
                };
            }

            let tiposDC = Object.entries(CookieConfig);

            for (let i = 0; i < tiposDC.length; i++) {
                if (tiposDC[i][0] == "aceiteTermos") {
                    if (tiposDC[i][1] == "S") {
                        isCheckedAceiteTermos = true;
                    }
                }
            }

            var item;

            for (let i = 0; i < tiposDC.length; i++) {
                item = tiposDC[i];
                for (let y = 0; y < tiposDeCookies.length; y++) {
                    if (tiposDeCookies[y].id == item[0]) {
                        var valor = [];
                        if (item[1] == "S") {
                            valor.push(item[0]);
                        }
                        tiposDeCookies[y].valor = valor;
                        continue;
                    }
                }
            }

            for (let i = 0; i < tiposDC.length; i++) {
                item = tiposDC[i];
                for (let y = 0; y < tiposDeCookiesObrigatorios.length; y++) {
                    if (tiposDeCookiesObrigatorios[y].id == item[0]) {
                        var valor = [];
                        if (item[1] == "S") {
                            valor.push(item[0]);
                        } else {
                            listPermissaoObrigatoriaNaoEfetuada.push(item);
                            algumaPermissaoObrigatoriaNaoEfetuada = true;
                        }
                        tiposDeCookiesObrigatorios[y].valor = valor;
                        continue;
                    }
                }
            }

            if (CookieConfig.aceiteTermos === "N") {
                algumaPermissaoObrigatoriaNaoEfetuada = true;
            }

            if (!algumaPermissaoObrigatoriaNaoEfetuada) {
                exibepanelatencao = "0";
            }
        } else {
            /*se nao existir*/
            tiposDeCookies.forEach(MontarConfig);
            CookieConfig.aceiteTermos = "N";
            var myJSON = JSON.stringify(CookieConfig);
            setCookie("SWCookieConfig", myJSON);

            if (!AcessoAnonimo) {
                showpanelatencao = "1";
                algumaPermissaoObrigatoriaNaoEfetuada = true;
            }
        }
    }

    export function CarregaConfiguracoesDoUsuarioAPI() {
        ExisteNaAPI = false; /*existe configuracao em banco*/
        ExibePanelTermo = true;
        ExibePanelUsoCookies = true;

        CookieConfig = {};
        let retornoJSON = [];
        console.log("tppessoacompgrafico:" + tppessoacompgrafico);
        console.log("pessoacompgrafico:" + pessoacompgrafico);
        let result = ObtemConfiguracoesDoUsuarioAPI(
            tppessoacompgrafico,
            pessoacompgrafico
        );

        return result.then(
            function (retorno) {
                retornoJSON = retorno;

                console.log(
                    "[CarregaConfiguracoesDoUsuarioAPI] - 1 Retornou valor de ObtemConfiguracoesDoUsuarioAPI"
                );
                try {
                    /*carrega o cookie a partir da api*/
                    CookieConfig = {};

                    if (!Object.entries) {
                        Object.entries = function (obj) {
                            var ownProps = Object.keys(obj),
                                i = ownProps.length,
                                resArray = new Array(i); // preallocate the Array
                            while (i--)
                                resArray[i] = [ownProps[i], obj[ownProps[i]]];

                            return resArray;
                        };
                    }

                    Object.entries(retorno).forEach(MontarConfigApi);

                    var myJSON = JSON.stringify(CookieConfig);
                    setCookie("SWCookieConfig", myJSON);

                    var dataAtual = new Date();
                    var from = retornoJSON.DtAtualizacao.split("/");

                    let dia = parseInt(from[0]);
                    let mes = parseInt(from[1]) - 1;
                    let ano = parseInt(from[2]);

                    let dataUltimaConfiguracao = new Date(ano, mes, dia);

                    ExisteNaAPI = true;
                    if (
                        VerificaSePeriodoDoAceiteExpirou(
                            dataAtual,
                            dataUltimaConfiguracao
                        )
                    ) {
                        console.log(
                            "[CarregaConfiguracoesDoUsuarioAPI] - 2 Aceite expirou"
                        );
                        AceiteExpirou = true;
                    }
                    {
                        console.log(
                            "[CarregaConfiguracoesDoUsuarioAPI] - 2 Aceite N expirou"
                        );
                        AceiteExpirou = false;
                    }
                } catch (error) {
                    console.log(
                        "[CarregaConfiguracoesDoUsuarioAPI] - 1.1 Retornou erro de ObtemConfiguracoesDoUsuarioAPI"
                    );
                    console.log("Erro ao consultar a data na api");
                    //evitar o erro split
                }

                //se retorno 00 ou 07 é saída simplificada
                if (retornoJSON.CodRetorno == "00") {
                    console.log(
                        "[CarregaConfiguracoesDoUsuarioAPI] - 3  Retorno 00"
                    );
                    ExisteNaAPI = true;
                } else if (retornoJSON.CodRetorno == "07") {
                    console.log(
                        "[CarregaConfiguracoesDoUsuarioAPI] - 3 Retorno O7"
                    );
                    ExisteNaAPI = false;
                } else {
                    algumaPermissaoObrigatoriaNaoEfetuada = true;
                    if (retornoJSON.MensagemRetorno !== undefined) {
                        console.log(
                            "[CarregaConfiguracoesDoUsuarioAPI] - 4 Erro ao carregar o componente de configuracao de cookies: " +
                                retornoJSON.MensagemRetorno
                        );
                    } else {
                        console.log(retornoJSON.MensagemRetorno);
                    }
                }
            },

            function (error) {
                console.log(
                    "[CarregaConfiguracoesDoUsuarioAPI] - 1 Erro ao carregar o componente de configuracao de cookies!",
                    error
                );
                algumaPermissaoObrigatoriaNaoEfetuada = true;
            }
        );
    }

    /* Inicio Funcoes de Cookies   ----------------------------------------------------------------- */
    function VerificaSePeriodoDoAceiteExpirou(
        dataAtual,
        dataUltimaConfiguracao
    ) {
        let startDay = new Date(dataAtual);
        let endDay = new Date(dataUltimaConfiguracao);
        let millisecondsPerDay = 1000 * 60 * 60 * 24;

        let millisBetween = endDay.getTime() - startDay.getTime();
        let days = millisBetween / millisecondsPerDay;
        let result = Math.floor(days);
        let diasDecorridos = Math.abs(result);
        /*console.log("dias decorridos (" + diasDecorridos+ ") > revogação ("+periodoRevogacaoInt+") : " ,  (diasDecorridos > periodoRevogacaoInt))*/
        if (diasDecorridos > periodoRevogacao) {
            return true;
        }
        return false;
    }

    function setCookie(cname, cvalue) {
        let exdays = periodoRevogacao;
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie =
            cname + "=" + cvalue + ";" + expires + ";path=/" + ";";

        console.log("conteudo a gravar no cookie", cvalue);
    }

    function getCookie(cname) {
        var name = cname + "=";
        try {
            var ca = document.cookie.split(";");
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
        } catch (error) {
            /* evitar o erro do split*/
        }

        return "";
    }

    function checkIfCookieExist(nome) {
        var user = getCookie(nome);
        if (user != "") {
            return true;
        } else {
            return false;
        }
    }
    /* Fim  Funcoes de Cookies   ----------------------------------------------------------------- */

    /*Carregado no load da pagina - cria Config a partir da lista da api */
    function MontarConfig(item) {
        var valor = "N";

        if (item.valor.length != 0) {
            valor = "S";
        }

        Object.defineProperty(CookieConfig, item.id, {
            value: valor,
            writable: true,
            enumerable: true,
            configurable: true,
        });
    }

    function MontarConfigApi(item) {
        /*apenas as propridades com a palavrava aceite*/
        if (item[0].includes("aceite")) {
            var valor = "N";
            if (item[1] === "S") {
                valor = "S";
            }

            Object.defineProperty(CookieConfig, item[0], {
                value: valor,
                writable: true,
                enumerable: true,
                configurable: true,
            });

            if (item[0] == "aceiteTermos") {
                if (item[1] === "S") {
                    isCheckedAceiteTermos = true;
                }
            }
        }
    }

    /*valida se todos os parametros foram informados pelo aplicação cliente */
    function ValidarConfiguracoesCookies(
        pPessoaCompGrafico,
        pTpPessoaCompGrafico,
        pIndSaidaSimplificada,
        pTokencac
    ) {
        /*  if (
            pTokencac == "" ||
            pTokencac == undefined ||
            pTokencac == "undefined"
        ) {
            alert("Token  de autenticação não informado.");
            return false;
        }

        if (
            urlapi == "" ||
            urlapi == undefined ||
            urlapi == "undefined"
        ) {
            alert("A url da api de persistencia dos cookies não foi informada.");
            return false;
        }*/

        if (
            pIndSaidaSimplificada == undefined ||
            pIndSaidaSimplificada === "" ||
            (pIndSaidaSimplificada != "0" && pIndSaidaSimplificada != "1")
        ) {
            if (pIndSaidaSimplificada != "0" && pIndSaidaSimplificada != "1") {
                alert("Indicador de saída inválido.");
            } else {
                alert("Indicador de saída simplificada não informado.");
            }

            return false;
        } else {
            if (pPessoaCompGrafico != undefined && pPessoaCompGrafico !== "") {
                if (
                    pTpPessoaCompGrafico == undefined ||
                    pTpPessoaCompGrafico === ""
                ) {
                    alert(
                        "Ao informar o campo PessoaCompGrafico deve se informar o TpPessoaCompGrafico."
                    );
                    return false;
                }
            }

            if (
                pTpPessoaCompGrafico != undefined &&
                pTpPessoaCompGrafico !== ""
            ) {
                if (
                    pPessoaCompGrafico == undefined ||
                    pPessoaCompGrafico === ""
                ) {
                    alert(
                        "Ao informar o campo TpPessoaCompGrafico deve se informar o campo PessoaCompGrafico."
                    );
                    return false;
                }
            }
        }
    }

    export function ValidaObrigatorios() {
        var item;

        for (let i = 0; i < tiposDeCookiesPosEscolha.length; i++) {
            item = tiposDeCookiesPosEscolha[i];

            for (let y = 0; y < tiposDeCookiesObrigatorios.length; y++) {
                if (tiposDeCookiesObrigatorios[y].id == item.id) {
                    if (item.valor.length == 0) {
                        return false;
                    }
                }
            }
        }

        if (!isCheckedAceiteTermos) {
            return false;
        }

        return true;
    }

    export function ExibicaoPanelAtencao(value) {
        showpanelatencao = value ? "1" : "0";

        this.dispatchEvent(
            new CustomEvent("ExibicaoPanelAtencao", {
                composed: true,
                detail: { key: false },
            })
        );
    }

    export function GetExibicaoPanelAtencao() {
        this.dispatchEvent(
            new CustomEvent("GetExibicaoPanelAtencao", {
                composed: true,
                detail: { key: false },
            })
        );

        return showpanelatencao;
    }

    function handlePoliticaLinkClick(e) {
        e.preventDefault();

        var win = window.open(footer_urlpolitica, "_blank");
        win.focus();
    }

    function handleKeydownModaPolitica(event) {
        event.preventDefault();
        if (`${event.key}` == "Escape") {
            closePolitica();
        }
    }

    function handleKeydownModaDefinicoes(event) {
        event.preventDefault();
        if (`${event.key}` == "Escape") {
            closeDefincoes();
        }
    }

    /*esq para fechar modais*/
    function handleKeydownModal(event) {
        event.preventDefault();
        if (`${event.key}` == "Escape") {
            HideModalConfirmAceiteTermoResponsa();
        }
    }

    /*esq para fechar modais*/
    function handleKeydownAtencao(event) {
        event.preventDefault();
        if (`${event.key}` == "Escape") {
            SairSemAutorizar();
        }
    }

    /*abrir e fechar popup de Cookies*/
    function close() {
        isOpen = false;
    }

    /*abrir e fechar popup de Definicoes*/
    function openDefincoes() {
        isOpenDefincoes = true;
    }
    function closeDefincoes() {
        isOpenDefincoes = false;
    }

    /*abrir e fechar popup de Definicoes*/
    function openPolitica() {
        isOpenPolitica = true;
    }
    function closePolitica() {
        isOpenPolitica = false;
    }

    export function HideModalConfirmAceiteTermoResponsa() {
        try {
            close();
        } catch (error) {
            /*nada  a fazer*/
        }

        try {
            closeDefincoes();
        } catch (error) {
            /*nada  a fazer*/
        }

        try {
            closePolitica();
        } catch (error) {
            /*nada  a fazer*/
        }
    }

    function Redirecionar(strUrl) {
        var validURL = strUrl.toUpperCase().indexOf("HTTP") > -1;

        if (validURL) {
            if (strUrl.toUpperCase().substring(0, 4) != "HTTP") {
                //endereco relativo
                window.location.replace(strUrl);
            } else if (location.protocol == strUrl.split("/")[0]) {
                //mesmo protocolo
                window.location.replace(strUrl);
            } else {
                alert(
                    "O endereço de retorno está com protocolo diferente da pagina atual. Será necessário clicar novamente no botão do consentimento de cookie."
                );
                location.replace(
                    window.location.href.replace("http:", "https:")
                );
            }
        } else {
            document.querySelector("#" + strUrl).click();
        }

        return false;
    }

    function DesistirAceiteTermoResponsaSimplAnonimo() {
        CookieConfig = {};

        for (let i = 0; i < tiposDeCookies.length; i++) {
            tiposDeCookies[i].valor = [];
        }

        tiposDeCookies.forEach(MontarConfig);
        CookieConfig.aceiteTermos = "N";
        var myJSON = JSON.stringify(CookieConfig);
        setCookie("SWCookieConfig", myJSON);
    }

    function salvar() {
        /* precisa de todos os tipos de cookies por isso inicial com todos*/
        tiposDeCookiesPosEscolha = tiposDeCookies;

        /*popular a lista para gravação*/
        if (indsaidasimplificada == "1") {
            /* se lista simplificada */
            tiposDeCookiesObrigatorios.forEach(ObterCheckboxSelecionados);
        }

        var obrigatoriosNaoPreenchidos = false;
        var usuarioConfirmouNaoAceite = false;

        /*valida os obrigatorios*/
        if (!ValidaObrigatorios()) {
            obrigatoriosNaoPreenchidos = true;
            usuarioConfirmouNaoAceite = confirm(tx_confirmar_sair);

            if (!usuarioConfirmouNaoAceite) {
                return {
                    CodRetorno: "888",
                    MensagemRetorno:
                        "Error: Não foram selecionados os obrigatórios.",
                };
            }
        }

        var retornoFinalizar = { CodRetorno: "00", MensagemRetorno: "" };

        if (AcessoAnonimo) {
            retornoFinalizar = FinalizarConfiguracaoModoAnonimo();
        } else {
            retornoFinalizar = FinalizarConfiguracaoModoNaoAnonimo();
        }

        if (obrigatoriosNaoPreenchidos && usuarioConfirmouNaoAceite) {
            return {
                CodRetorno: "889",
                MensagemRetorno:
                    "Usuário não consentiu com os termos obritórios",
            };
        }

        return retornoFinalizar;
    }

    function ObterCheckboxSelecionados(item) {
        for (let i = 0; i < tiposDeCookiesPosEscolha.length; i++) {
            if (tiposDeCookiesPosEscolha[i].id == item.id) {
                tiposDeCookiesPosEscolha[i].valor = item.valor;
            }
        }
    }

    /*Finaliza configuracao modo nao anonimo - Nome correto (FinalizarConfiguracaoModoNaoAnonimo)*/
    function FinalizarConfiguracaoModoNaoAnonimo() {
        /*monta configs */
        CookieConfig = {};
        tiposDeCookiesPosEscolha.forEach(MontarConfig);
        CookieConfig.aceiteTermos = isCheckedAceiteTermos ? "S" : "N";
        var myJSON = JSON.stringify(CookieConfig);
        setCookie("SWCookieConfig", myJSON);

        /*Precisa verificar se o usuario ja existe ou nao p definir o que fazer.      */
        if (ExisteNaAPI) {
            console.log("chamou AlterarConfiguracoesDoUsuarioAPI");
            return AlterarConfiguracoesDoUsuarioAPI(
                pessoacompgrafico,
                tppessoacompgrafico,
                CookieConfig.aceiteTermos,
                CookieConfig.aceiteSessao,
                CookieConfig.aceiteDesempenho,
                CookieConfig.aceiteEstatisticos,
                CookieConfig.aceitePersistentes
            );
        } else {
            console.log("chamou IncluirConfiguracoesDoUsuarioAPI");
            return IncluirConfiguracoesDoUsuarioAPI(
                pessoacompgrafico,
                tppessoacompgrafico,
                CookieConfig.aceiteTermos,
                CookieConfig.aceiteSessao,
                CookieConfig.aceiteDesempenho,
                CookieConfig.aceiteEstatisticos,
                CookieConfig.aceitePersistentes
            );
        }
    }

    /*Finaliza configura modo simplificado anonimo Nome correto - FinalizarConfiguracaoModoAnonimo*/
    function FinalizarConfiguracaoModoAnonimo() {
        /* forcar valores no anonimo*/
        CookieConfig = {};
        tiposDeCookiesPosEscolha.forEach(MontarConfig);
        CookieConfig.aceiteTermos = isCheckedAceiteTermos ? "S" : "N";

        var myJSON = JSON.stringify(CookieConfig);
        setCookie("SWCookieConfig", myJSON);

        return { CodRetorno: "00", MensagemRetorno: "" };
    }

    function ConfigurarListaParaGravacao(ApenasCoockiesObrigatorios) {
        if (indsaidasimplificada == "1" || ApenasCoockiesObrigatorios) {
            console.log("apenas obrigatorios");
            /* se lista simplificada (apenas aceites obrigatorios) */

            /*deixa todas as escolhas em branco*/
            for (let i = 0; i < tiposDeCookiesPosEscolha.length; i++) {
                tiposDeCookiesPosEscolha[i].valor = [];
            }

            /*marcar todos os obrigatorios*/
            for (let i = 0; i < tiposDeCookiesObrigatorios.length; i++) {
                var item = [];
                item.push(tiposDeCookiesObrigatorios[i].id);
                tiposDeCookiesObrigatorios[i].valor = item;
            }
        } else {
            //0, então ão simplificada (lista completa)
            //carrega todas as escolhas na listaTiposDeCookies
            for (let i = 0; i < tiposDeCookiesPosEscolha.length; i++) {
                var item = [];
                item.push(tiposDeCookies[i].id);
                tiposDeCookiesPosEscolha[i].valor = item;
            }
        }
    }

    /* funcoes webapi ------------------------------------------------------------------------------*/

    function getRequest(url, method, jsonParameter) {
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();

            xhr.open(method, url, true);
            xhr.setRequestHeader(
                "Content-type",
                "application/json; charset=utf-8"
            );
            xhr.setRequestHeader("Authorization", "Bearer " + tokencac);
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.setRequestHeader(
                "Access-Control-Allow-Methods",
                "ACL, CANCELUPLOAD, CHECKIN, CHECKOUT, COPY, DELETE, GET, HEAD, LOCK, MKCALENDAR, MKCOL, MOVE, OPTIONS, POST, PROPFIND, PROPPATCH, PUT, REPORT, SEARCH, UNCHECKOUT, UNLOCK, UPDATE, VERSION-CONTROL"
            );
            xhr.setRequestHeader(
                "Access-Control-Allow-Headers",
                "Overwrite, Destination, Content-Type, Depth, User-Agent, Translate, Range, Content-Range, Timeout, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control, Location, Lock-Token, If"
            );
            xhr.setRequestHeader(
                "Access-Control-Expose-Headers",
                "DAV, content-length, Allow"
            );
            xhr.setRequestHeader("Access-Control-Allow-Credentials", true);
            xhr.timeout = 4000; // Set timeout to 4 seconds (4000 milliseconds)
            xhr.ontimeout = function () {
                console.log("Request Timeout: 4s");
            };

            xhr.onload = function () {
                if (xhr.status == 200) {
                    let retorno = JSON.parse(xhr.responseText);
                    if (retorno.CodRetorno != undefined) {
                        if (!retorno.CodRetorno != "00") {
                            console.log("ERRO NO WEBSERVICE", xhr.responseText);
                            console.log("Parametros:", jsonParameter);
                        }
                    }

                    resolve(retorno);
                } else {
                    console.log("FALHA", xhr.responseText);
                    reject(
                        JSON.parse(
                            '{"CodRetorno":"' +
                                999 +
                                '", "MensagemRetorno":"' +
                                xhr.statusText +
                                '"}'
                        )
                    );
                }
            };

            xhr.onerror = function () {
                let texterro = "Erro ao obter dados da api.";
                if (xhr.status == 401) {
                    texterro + " ( Token de acesso Expirado ) ";
                }
                console.log("** An error occurred during the transaction");
                reject(
                    JSON.parse(
                        '{"CodRetorno":"' +
                            999 +
                            '", "MensagemRetorno":"' +
                            texterro +
                            '"}'
                    )
                );
            };

            xhr.send(JSON.stringify(jsonParameter));
        });
    }

    function GetParametroRevogacao() {
        if (urlapi === "") {
            return new Promise((resolve, reject) => {
                resolve(periodoRevogacao);
            });
        } else {
            return getRequest(urlapi + "GetPeriRevogPolitCookies", "Post", {});
        }
    }

    export function ObtemConfiguracoesDoUsuarioAPI(
        pessoaIndicador,
        pessoaNumero
    ) {
        pessoaNumero = pessoaNumero.toString().replace(/[^\d]+/g, "");
        let dataJSON = {
            pessoaNumero: pessoaNumero,
            pessoaIndicador: pessoaIndicador,
        };
        return getRequest(urlapi + "consultar", "Post", dataJSON);
    }

    function IncluirConfiguracoesDoUsuarioAPI(
        pessoaNumero,
        pessoaIndicador,
        aceiteTermos,
        aceiteSessao,
        aceiteDesempenho,
        aceiteEstatisticos,
        aceitePersistentes
    ) {
        pessoaNumero = pessoaNumero.replace(/[^\d]+/g, "");
        console.log("IncluirConfiguracoesDoUsuarioAPI - salvando não anonimo");
        let dataJSON = {
            pessoaNumero: pessoaNumero,
            pessoaIndicador: pessoaIndicador,
            aceiteTermos: aceiteTermos,
            aceiteSessao: aceiteSessao,
            aceiteDesempenho: aceiteDesempenho,
            aceiteEstatisticos: aceiteEstatisticos,
            aceitePersistentes: aceitePersistentes,
        };

        return getRequest(urlapi + "Inserir", "Post", dataJSON);
    }

    function AlterarConfiguracoesDoUsuarioAPI(
        pessoaNumero,
        pessoaIndicador,
        aceiteTermos,
        aceiteSessao,
        aceiteDesempenho,
        aceiteEstatisticos,
        aceitePersistentes
    ) {
        pessoaNumero = pessoaNumero.replace(/[^\d]+/g, "");

        let dataJSON = {
            pessoaNumero: pessoaNumero,
            pessoaIndicador: pessoaIndicador,
            aceiteTermos: aceiteTermos,
            aceiteSessao: aceiteSessao,
            aceiteDesempenho: aceiteDesempenho,
            aceiteEstatisticos: aceiteEstatisticos,
            aceitePersistentes: aceitePersistentes,
        };

        return getRequest(urlapi + "Alterar", "Post", dataJSON);
    }
</script>

<main>
    <!-- Inicio html- regiao do Componente Panel Atencao -----  import Atencao  from './Atencao.svelte';                   -->
    {#if showpanelatencao == "1"}
        <div
            id="modalPanelAtencao"
            class="cc__modal"
            on:keydown={handleKeydownAtencao}
            tabindex={0}
        >
            <div />

            <div class="cc__content__wrapper">
                <h2 style="text-align:center;">{name}</h2>

                <div class="cc__panel">
                    <div class="cc__panel__header">
                        <h3>{panel_central_title}</h3>
                    </div>
                    <div class="cc__panel__body__container">
                        <div class="cc__panel__body">
                            <p>
                                {@html panel_textoatencao}
                                <!--chamada do modal aqui-->
                                <input
                                    type="button"
                                    class="cc__linkbutton"
                                    on:click={openDefincoes}
                                    value={panel_textodefinicaocookies}
                                />
                            </p>
                            <p>
                                {@html panel_textoconsentimento}

                                <button
                                    class="cc__linkbutton"
                                    on:click={handlePoliticaLinkClick}
                                    >{panel_textolinkpolitica}</button
                                >
                            </p>
                            <div class="cc__panel__aut__buttons">
                                <input
                                    type="button"
                                    class="cc__button__autorizacao--all"
                                    role="tab"
                                    on:click={AutorizarCookiesEPolitica}
                                    value="Autorizo o uso de todos os cookies e estou de acordo com a pol&iacute;tica de privacidade."
                                />
                                <input
                                    type="button"
                                    class="cc__button__autorizacao"
                                    role="tab"
                                    on:click={AutorizarCookiesNecessariosEPolitica}
                                    value="Use somente cookies necess&aacute;rios. Estou de acordo com a pol&iacute;tica de privacidade."
                                />
                                <input
                                    type="button"
                                    class="cc__button__autorizacao"
                                    role="tab"
                                    on:click={AutorizarPoliticaEEscolherCookies}
                                    value="Vou escolher os cookies. Estou de acordo com a pol&iacute;tica de privacidade."
                                />
                                <input
                                    type="button"
                                    class="cc__button__autorizacao"
                                    role="tab"
                                    on:click={SairSemAutorizar}
                                    value="Sair sem autorizar "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/if}
    <!-- Fim html- regiao do Atencao -----  import Atencao  from './Atencao.svelte';                   -->

    <!-- Inicio html- regiao do footer -----  import Footer  from './Footer.svelte';                   -->
    {#if exibepanelfooter == "1"}
        <footer id="cc__footer">
            <div id="cc__footer__content">
                <div>
                    <p>
                        {footer_textoatencao} <br />
                        {@html footer_textowebsite}<a
                            href="/"
                            on:click={handlePoliticaLinkClick}
                            >{footer_website}</a
                        >
                    </p>
                    <div style="text-align:center; margin-top: -13px;">
                        <button
                            class="cc_prefBotton"
                            on:click={AutorizarPoliticaEEscolherCookies}
                            >Preferências de Cookies</button
                        >
                        <button
                            class="cc_okBotton"
                            on:click={AutorizarCookiesEPolitica}>OK</button
                        >
                    </div>
                </div>
            </div>
        </footer>
    {/if}
    <!-- Fim html - regiao do footer -----  import Footer  from './Footer.svelte';                     -->

    <!-- Inicio html- regiao do modal -----  import Footer  from './Modal.svelte';                   -->
    {#if isOpen}
        <div
            id="modalTermo"
            class="cc__modal"
            on:keydown={handleKeydownModal}
            tabindex={0}
        >
            <div on:click={close} />

            <div class="cc__content__wrapper_cookie">
                <div class="cc__modalDivOrgao">
                    <img
                        style="float: left"
                        src="https://senhawebsts.prefeitura.sp.gov.br/prodam.cdn.senhaweb/img/logosp.png"
                        alt="Logo Prefeitura de São Paulo"
                    />

                    <img
                        style="float: right"
                        src="https://senhawebsts.prefeitura.sp.gov.br/prodam.cdn.senhaweb/img/logopmsp.png"
                        alt="Logo Prefeitura de São Paulo"
                    />
                </div>

                <div class="cc__modal__titulo">
                    <h1>{modal_central_title}</h1>
                </div>

                <div class="cc_content">
                    {#if ExibePanelTermo}
                        <div class="cc__panelModal">
                            <div class="cc__panelModal__header">
                                <h3>{modal_termo_responsabilidade_title}</h3>
                            </div>

                            <div class="cc__panelModal__body__container">
                                <div class="cc__panelModal__body">
                                    <p>
                                        {@html modal_termo_textoresponsabilidade}
                                    </p>
                                    <p>
                                        {@html modal_termo_preencherresponsabilidade}
                                    </p>
                                    <ul style="list-style-type:none;">
                                        <li>
                                            <input
                                                type="checkbox"
                                                bind:checked={isCheckedAceiteTermos}
                                            />
                                            <b
                                                >Aceito os termos de
                                                responsabilidade
                                            </b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    {/if}
                    {#if ExibePanelUsoCookies}
                        <div class="cc__panelModal" style="margin-top:20px;">
                            <div class="cc__panelModal__header">
                                <h3>{modal_usocookie_title}</h3>
                            </div>

                            {#if !AcessoAnonimo}
                                <div class="cc__panelModal__body__container">
                                    <div class="cc__panelModal__body">
                                        <p>{@html modal_usocookie_texto}</p>
                                        <p>{@html modal_usocookie_preencher}</p>

                                        <ul style="list-style-type:none;">
                                            {#if indsaidasimplificada == "0"}
                                                {#each tiposDeCookies as tipoCookie, index (tipoCookie.id)}
                                                    <li>
                                                        <input
                                                            type="checkbox"
                                                            bind:group={tipoCookie.valor}
                                                            value={tipoCookie.id}
                                                        />
                                                        <b
                                                            >{tipoCookie.descricao}</b
                                                        >
                                                    </li>
                                                {/each}
                                            {:else}
                                                {#each tiposDeCookiesObrigatorios as tipoCookie, index (tipoCookie.id)}
                                                    <li>
                                                        <input
                                                            type="checkbox"
                                                            bind:group={tipoCookie.valor}
                                                            value={tipoCookie.id}
                                                        />
                                                        <b
                                                            >{tipoCookie.descricao}</b
                                                        >
                                                    </li>
                                                {/each}
                                            {/if}
                                        </ul>
                                    </div>
                                </div>

                                <div style="width:100%; text-align: center; ">
                                    <div
                                        role="button"
                                        aria-pressed="false"
                                        tabindex="0"
                                        id="continuarapp"
                                        class="cc__linkbutton"
                                        on:click={SalvarConsentimento}
                                    >
                                        Continuar
                                    </div>
                                </div>
                            {:else}
                                <div class="cc__panelModal__body__container">
                                    {#if indsaidasimplificada == "1"}
                                        <div class="cc__panelModal__body">
                                            <p>
                                                {@html modal_usocookie_txsimplificado}
                                            </p>
                                            <p>
                                                {@html footer_textowebsite}<a
                                                    href="/"
                                                    on:click={handlePoliticaLinkClick}
                                                    >{footer_website}</a
                                                >
                                            </p>

                                            <div
                                                style="text-align:center; padding-bottom:10px;"
                                            >
                                                <button
                                                    class="cc_prefBotton"
                                                    on:click={() => {
                                                        Redirecionar(
                                                            footer_urlpolitica
                                                        );
                                                    }}
                                                    >Retornar ao Portal PMSP</button
                                                >
                                                <button
                                                    class="cc_prefBotton"
                                                    on:click={AutorizarCookiesNecessariosEPolitica}
                                                    >Aceitar e Continuar</button
                                                >
                                            </div>
                                        </div>
                                    {:else}
                                        <div class="cc__panelModal__body">
                                            <p>{@html modal_usocookie_texto}</p>
                                            <p>
                                                {@html modal_usocookie_preencher}
                                            </p>
                                            <ul style="list-style-type:none;">
                                                {#each tiposDeCookies as tipoCookie, index (tipoCookie.id)}
                                                    <li>
                                                        <input
                                                            type="checkbox"
                                                            bind:group={tipoCookie.valor}
                                                            value={tipoCookie.id}
                                                        />
                                                        <b
                                                            >{tipoCookie.descricao}</b
                                                        >
                                                    </li>
                                                {/each}
                                            </ul>
                                        </div>

                                        <div
                                            style="width:100%; text-align: center; "
                                        >
                                            <input
                                                type="button"
                                                id="continuarappa"
                                                class="cc__linkbutton"
                                                on:click={SalvarConsentimento}
                                                value="Continuar"
                                            />
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    {/if}
    <!-- Fim html- regiao do modal -----  import Footer  from './Modal.svelte';                   -->

    {#if isOpenPolitica}
        <div
            class="cc__modal"
            on:keydown={handleKeydownModaPolitica}
            tabindex={0}
        >
            <div on:click={closePolitica} />

            <div class="cc__content__wrapper_cookie">
                <slot name="header">
                    <div class="cc__modalDivOrgao">
                        <img
                            style="float: left"
                            src="https://senhawebsts.prefeitura.sp.gov.br/prodam.cdn.senhaweb/img/logosp.png"
                            alt="Logo Prefeitura de São Paulo"
                        />

                        <img
                            style="float: right"
                            src="https://senhawebsts.prefeitura.sp.gov.br/prodam.cdn.senhaweb/img/logopmsp.png"
                            alt="Logo Prefeitura de São Paulo"
                        />
                    </div>
                    <div class="cc__modal__titulo">
                        <h1>{modal_central_title}</h1>
                    </div>
                </slot>
                <slot name="content">
                    <div class="cc__panelModal">
                        <div class="cc__panelModal__header">
                            <h3>
                                Política <button
                                    class="cc__linkbutton"
                                    style="float:right;"
                                    on:click={closePolitica}>Fechar</button
                                >
                            </h3>
                        </div>
                        <div class="cc__panelModal__body__container">
                            <iframe
                                title="Política"
                                id="cc_politica"
                                style="width:100%; height: 100%; min-height: 900px;"
                                sandbox="allow-same-origin allow-forms allow-popups allow-scripts"
                                src={footer_urlpolitica}
                            />
                        </div>
                    </div>
                </slot>
            </div>
        </div>
    {/if}

    <!-- Inicio html- regiao do modal Definicoes -----  import Footer  from './Modal.svelte';                   -->
    {#if isOpenDefincoes}
        <div
            class="cc__modal"
            on:keydown={handleKeydownModaDefinicoes}
            tabindex={0}
        >
            <div on:click={closeDefincoes} />

            <div class="cc__content__wrapper_cookie">
                <slot name="header">
                    <div class="cc__modalDivOrgao">
                        <img
                            style="float: left"
                            src="https://senhawebsts.prefeitura.sp.gov.br/prodam.cdn.senhaweb/img/logosp.png"
                            alt="Logo Prefeitura de São Paulo"
                        />

                        <img
                            style="float: right"
                            src="https://senhawebsts.prefeitura.sp.gov.br/prodam.cdn.senhaweb/img/logopmsp.png"
                            alt="Logo Prefeitura de São Paulo"
                        />
                    </div>
                    <div class="cc__modal__titulo">
                        <h1>{modal_central_title}</h1>
                    </div>
                </slot>

                <slot name="content">
                    <div class="cc__panelModal">
                        <div class="cc__panelModal__header">
                            <h3>
                                O que s&atilde;o cookies? <button
                                    class="cc__linkbutton"
                                    style="float:right;"
                                    on:click={closeDefincoes}>Fechar</button
                                >
                            </h3>
                        </div>
                        <div class="cc__panelModal__body__container">
                            <div class="cc__panelModal__body">
                                <p>
                                    <b>Cookies</b> s&atilde;o arquivos salvos em
                                    seu computador, tablet ou telefone quando voc&ecirc;
                                    visita um site. Elas servem para controlar o
                                    tr&aacute;fego, identificar o in&iacute;cio de
                                    uma sess&atilde;o, armazenar conte&uacute;dos
                                    ou permitir a aplica&ccedil;&atilde;o de elementos
                                    de seguran&ccedil;a. Assim, permitem que as p&aacute;ginas
                                    carreguem mais r&aacute;pido e facilitam a navega&ccedil;&atilde;o.
                                </p>

                                <p style="color:red;">
                                    Os cookies utilizados nesse site n&atilde;o
                                    transmitem informa&ccedil;&otilde;es
                                    pessoalmente identific&aacute;veis a
                                    terceiros.
                                </p>

                                <p>
                                    <b>Cookies de sess&atilde;o</b>: tipo de
                                    cookie essencial para armazenar
                                    informa&ccedil;&otilde;es da sess&atilde;o
                                    do usu&aacute;rio autenticado. Sem esse
                                    cookie, as aplica&ccedil;&otilde;es que
                                    necessitam de autentica&ccedil;&atilde;o
                                    n&atilde;o conseguem funcionar corretamente.
                                    S&atilde;o armazenados no seu computador
                                    durante a sess&atilde;o da web e s&atilde;o
                                    exclu&iacute;dos automaticamente quando
                                    voc&ecirc; fecha o navegador - eles
                                    geralmente armazenam uma ID de sess&atilde;o
                                    an&ocirc;nima, permitindo que voc&ecirc;
                                    navegue em um site sem ter que fazer login
                                    em cada p&aacute;gina, mas n&atilde;o
                                    coletam nenhum dado pessoal.
                                </p>

                                <p>
                                    <b>Cookies persistentes</b>: s&atilde;o
                                    armazenados como um arquivo no seu
                                    computador e permanece l&aacute; quando
                                    voc&ecirc; fecha o navegador. O cookie pode
                                    ser lido pelo site que o criou quando
                                    voc&ecirc; visitar esse site novamente. Esse
                                    tipo de cookie n&atilde;o deve ser
                                    obrigat&oacute;rio e o usu&aacute;rio pode
                                    escolher n&atilde;o autoriz&aacute;-lo.
                                </p>

                                <p>
                                    <b>Cookies de desempenho</b>: Esses cookies
                                    nos permitem monitorar e melhorar o
                                    desempenho do nosso site. Por exemplo, eles
                                    nos permitem contar visitas, identificar
                                    origens de tr&aacute;fego e ver quais partes
                                    do site s&atilde;o mais populares e auxiliar
                                    na an&aacute;lise de melhorias. S&atilde;o
                                    geralmente origin&aacute;rios de ferramentas
                                    externas ao dom&iacute;nio principal e, por
                                    isso devem ser opcionais ao usu&aacute;rio.
                                </p>

                                <p>
                                    <b>Cookies estat&iacute;sticos</b>:
                                    conhecidos tamb&eacute;m por "cookie de
                                    terceiros", s&atilde;o cookies que guardam
                                    prefer&ecirc;ncias do usu&aacute;rio. Por
                                    exemplo, os cookies do Google Analytics.
                                    Esse tipo de cookie deve ser opcional e a
                                    aplica&ccedil;&atilde;o deve funcionar
                                    normalmente caso o usu&aacute;rio n&atilde;o
                                    autorize sua utiliza&ccedil;&atilde;o.
                                </p>
                            </div>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
    {/if}
</main>

<!-- <svelte:window on:keydown={handleKeydownModal} />  -->

<!-- Fim html- regiao do modal definicao -----  import Footer  from './Modal.svelte';                   -->
<style>
    /*regiao do footer*/
    #cc__footer {
        position: absolute;
        bottom: 14.5px;
        width: 99.3%;
        height: 56px; /* a altura total do footer   */
    }

    #cc__footer__content {
        background-color: #fff3d4;
        border: 1px solid #41320e;
        height: 52px; /* altura + top/bottom paddding + top/bottom border deve bater com alatura do footer */
        padding: 0px;
        text-align: justify;
        line-height: 0.9;
        text-indent: 0;
        margin-bottom: 1.05em;
        display: table;
        width: 99%;
    }

    #cc__footer__content p {
        padding: 0px;
        text-indent: 3ch;
        text-align: center;
        color: #838340;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.45px;
        font-size: small;
        margin-top: 1px;
    }

    .cc_okBotton {
        z-index: 2000;
        cursor: pointer;
        padding: 3px;
        border: 1px solid #4cae4c;
        color: #000;
        background-image: linear-gradient(45deg, #4cae4c 50%, transparent 50%);
        background-position: 100%;
        background-size: 400%;
        transition: background 300ms ease-in-out;
        width: 60px;
        font-size: small;
    }

    .cc_okBotton:hover {
        background-position: 0;
    }

    .cc_prefBotton {
        z-index: 2000;
        cursor: pointer;
        padding: 3px;
        border: 1px solid #4cae4c;
        background-image: linear-gradient(45deg, #4cae4c 50%, transparent 50%);
        background-position: 100%;
        background-size: 400%;
        transition: background 300ms ease-in-out;
        font-size: small;
    }

    .cc_prefBotton:hover {
        background-position: 0;
        color: #000000;
    }

    /*regiao do painel*/
    .cc__panel {
        border: 1px solid #ddd;
        background: #ffffff;
        border-radius: 0.4rem;
        width: 60%;
        margin: 0 20%;
        min-width: 0;
        float: left;
        box-shadow: 2px 1px 10px rgb(0 0 0 / 40%);
        font-size: small;
    }

    .cc__panel__header {
        background: #fff3d4;
        border-bottom: 1px solid #ddd;
        padding: 0.1rem 1rem 1rem;
        height: 36px;
        color: #78783c;
        font-size: large;
    }

    .cc__panel__body {
        background: #fdfcfa;
        height: auto;
        overflow: hidden;
        border: 1px solid #ddd;
        border-radius: 0.4rem;
        text-indent: 3ch;
        text-align: justify;
        font-size: medium;
        padding: 0 10px;
        width: calc(100% - 20px);
    }

    .cc__panel__body__container {
        background-color: transparent;
        width: 100%;
        padding: 0;
        margin: 0;
        float: left;
        position: relative;
    }

    .cc__panel__aut__buttons {
        background-color: transparent;
        font-size: large;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .cc__button__autorizacao {
        border: 2px solid black;
        padding: 5px 5px;
        height: 142px;
        width: 137px;
        min-width: 10rem;
        background-color: #ffffff;
        border-color: #06b9cd;
        color: #000000;
        font-size: medium;
        background-image: linear-gradient(45deg, #96d7de 50%, transparent 50%);
        background-position: 100%;
        background-size: 400%;
        transition: background 300ms ease-in-out;
        vertical-align: middle;
        text-align: center;
        margin: 0.2em;
        line-height: 20px;
        white-space: normal;
    }

    .cc__button__autorizacao:hover {
        background-position: 0;
        color: #000000;
    }

    .cc__button__autorizacao--all {
        border: 2px solid black;
        padding: 5px 5px;
        height: 142px;
        width: 137px;
        min-width: 10rem;
        background-color: #ffffff;
        border-color: #85cd06;
        color: #000000;
        font-size: medium;
        background-image: linear-gradient(45deg, #d9f0b1 50%, transparent 50%);
        background-position: 100%;
        background-size: 400%;
        transition: background 300ms ease-in-out;
        vertical-align: middle;
        text-align: center;
        margin: 0.2em;
        line-height: 20px;
        white-space: normal;
    }

    .cc__button__autorizacao--all:hover {
        background-position: 0;
        color: #000000;
    }

    :global(.cc__panel-minimal .cc__panel__body + .cc__panel__body) {
        border-top: 1px solid #eee;
    }

    /* regiao do modal*/
    /*.cc__modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;        
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10040;
         
        overflow: auto;
        overflow-y: auto;    
        padding-left: 10px;
        padding-right: 10px;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-animation-name: fadeIn;
        -webkit-animation-duration: 0.4s;
        animation-name: fadeIn;
        animation-duration: 0.4s;
        text-align: left;            
        transition: max-height 1s ease-in-out;
      }*/
    #modalPanelAtencao.cc__modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: block;
        z-index: 10040;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-animation-name: fadeIn;
        -webkit-animation-duration: 0.4s;
        animation-name: fadeIn;
        animation-duration: 0.4s;
        text-align: left;
        transition: max-height 1s ease-in-out;
        padding: 0;
        overflow: hidden;
    }
    #modalTermo.cc__modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: block;
        z-index: 10060;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-animation-name: fadeIn;
        -webkit-animation-duration: 0.4s;
        animation-name: fadeIn;
        animation-duration: 0.4s;
        text-align: left;
        transition: max-height 1s ease-in-out;
        padding: 0;
        overflow: hidden;
    }

    :global(.cc_hideModal) {
        max-height: 0;
        pointer-events: none;
    }

    .cc__content__wrapper_cookie {
        z-index: 10050;
        width: 100%;
        max-width: 100%;
        border-radius: 0.3rem;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        float: left;
        padding: 0;
        position: relative;
        background: #fff;
    }
    .cc__content__wrapper {
        z-index: 10050;
        width: 100%;
        max-width: 100%;
        border-radius: 0.3rem;
        height: 100%;
        overflow: hidden;
        float: left;
        padding: 0;
        position: relative;
    }

    .cc_content {
        /*  max-height: 50vh;*/
        overflow: auto;
        margin-top: 0;
    }

    .cc__modalDivOrgao {
        border: 1;
        background-color: #000;
        border-color: #000;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        padding-bottom: 50px;
        text-align: center;
    }

    .cc__modal__titulo {
        position: relative;
        color: #777;
        z-index: 1000;
        min-height: 50px;
        margin-top: -20px;
        border: 1px solid transparent;
        -webkit-box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.395);
        -moz-box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.395);
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.395);
        text-align: center;
    }

    .cc__panelModal {
        border: 1px solid #ddd;
        background: #ffffff;
        border-radius: 0.4rem;
        width: auto;
        margin: 0 10px;
        min-width: 0;
        float: left;
        box-shadow: 2px 1px 10px rgb(0 0 0 / 40%);
        font-size: small;
        width: calc(100% - 20px);
    }

    .cc__panelModal__body__container {
        background-color: transparent;
        padding-left: 20px;
        padding-right: 20px;
    }

    .cc__panelModal__header {
        border-bottom: 1px solid #000000;
        padding: 0.1rem 1rem 1rem;
        height: auto;
        color: #2e2e21;
        font-size: large;
        background: #dddddd;
        background-image: linear-gradient(45deg, #dddddd 50%, transparent 50%);
        background-position: 100%;
        background-size: 400%;
        transition: background 300ms ease-in-out;
    }

    .cc__panelModal__header:hover {
        background-position: 0;
        color: #000000;
    }

    .cc__panelModal__body {
        background: #ffffff;
        width: 100%;
        /*height: 100%;*/
        padding: 0;

        text-indent: 3ch;
        text-align: justify;
        font-size: medium;
    }

    .cc__linkbutton {
        background: none !important;
        border: none;
        padding: 0 !important;
        color: #069;
        text-decoration: underline;
        cursor: pointer;
        font-size: medium;
    }

    main {
        text-align: center;
        padding: 1em;
        max-width: 500px;
        margin: 0 auto;
    }

    h1 {
        color: #ff3e00;
        text-transform: uppercase;
        font-size: 4em;
        font-weight: 100;
    }
    h3 {
        margin: 8px 0;
    }
    @media (min-width: 640px) {
        main {
            max-width: none;
        }
    }
    @media (max-width: 600px) {
        .cc__panel__aut__buttons {
            background-color: transparent;
            font-size: large;
            margin: 0 auto;
            display: inline-block;
            width: 100%;
        }
        .cc__panel__aut__buttons input[type="button"] {
            width: 100%;
            height: auto;
            overflow: hidden;
            float: left;
        }
        
    }
    @media (min-width: 601px) {
        .cc__panel {
            border: 1px solid #ddd;
            background: #ffffff;
            border-radius: 0.4rem;
            width: 60%;
            margin: 0 20%;
            min-width: 0;
            float: left;
            box-shadow: 2px 1px 10px rgb(0 0 0 / 40%);
            font-size: small;
        }
        .cc__panel__aut__buttons {
            background-color: transparent;
            font-size: large;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
</style>